html {
  padding: 3em 0 0;
  margin: auto;
  line-height: 1.75;
  font-size: 1em;
  background-color: #111;
  scroll-behavior: smooth;
}

@media (max-width: 599px) {
  .mobile-thinner {
    width: 140%;
    max-width: none;
    margin-left: -30%;
  }
}

@media (min-width: 600px) {
  html {
    font-size: 1.37em;
  }
}


img {
  max-width: 100%;
  display: block;
}

h1,h2,h3,h4,h5,h6 {
  margin: 2em 0.5em 1em;
}

h4 {
  margin: .5em;
}

p,ul,ol {
  margin: 0.5em;
  margin-bottom: 2em;
  color: #ddd;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* font-family:             */
  /*       ui-monospace,      */
  /*       SFMono-Regular,    */
  /*       Consolas,          */
  /*       'Liberation Mono', */
  /*       Menlo,             */
  /*       monospace; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
