:root {
  --yellow: #ffe02e;
  --content: #222222ff;
}

a,
a:visited{
  color: var(--yellow);
  text-decoration: underline;
}

#canvas {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  /* background-image: url(../public/images/bg.png); */
  /* background-position: center; */
}

ul {
  padding-left: 1em;
}


.content-wrap {
  min-height: calc(90vh - 6.2rem);
  padding-bottom: 10vh;
}

.content,
.footer-content,
nav {
  margin: auto;
  max-width: 28rem;
}

nav,
.content-wrap {
  padding-left: 0.5em;
  padding-right: 0.5em;
}

.footer-content {
  padding: 1em 0.5em;
  max-width: 12rem;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

.footer-content > * {
  margin: 0.3em;
  flex-basis: 34%;
}

.footer {
  width: 100%;
  display: flex;
  background: var(--yellow);
  min-height: 100vh;
  font-size: 1.5em;
}


.content {
  overflow-x: hidden;
  position: relative;
  background: var(--content);
  color: white;
  border: 1px solid var(--yellow);
  padding: 0 0 0 0;
}

.text-small {
  font-size: 0.8em;
}

.main-link {
  font-size: 2.2em;
  text-decoration: none;
  margin: auto;
  text-align: center;
  display: block;
}

nav {
  margin: auto;
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: -1px;
  z-index: 1;
}

nav a {
  background: var(--content);
  text-decoration: none;
  border: 1px solid var(--yellow);
  padding: 0.2em 0.3em;
  padding-right: 0.5em;
}

nav a:last-child {
  border: 1px solid var(--yellow);
}

nav a.active {
  z-index: 0;
  border-bottom: 0;
}

.App {
  position: relative;
}

.playpause {
  background: none;
  padding: 0;
  font-weight: bold;
  border: none;
  width: 1em;
  height: 1em;
  min-width: 1.2rem;
  min-height: 1.2rem;
  font-size: 1em;
  vertical-align: sub;
}

.playpause svg {
  background: var(--yellow);
  border-radius: 50%;
}

.float-over {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  color: #fff;
  background: #00000099;
  padding: 0.5em 0.5em;
}

p {
  margin-bottom: 0.5em;
}


.cv h2,
.cv h3,
.cv h4 {
  margin: 0.5em 0;
}
.cv p {
  margin: 0.5em 0;
}

.indent{
  margin-left: 0.5em;
}
